const obj = {
  currencyFormat: (number = "") => {
    // return new Intl.NumberFormat('en-IN', {}).format(number)
    const options = { minimumFractionDigits: 2, maximumFractionDigits: 2 };
    const formatted = Number(number).toLocaleString("en", options);
    return formatted;
  },
  dateFormat: (date, timeZone) => {
    var convertedDate = new Date(date).toLocaleString(undefined, {
      timeZone:  "Asia/Kolkata",//timeZone ? timeZone :
    });

    return convertedDate.toString();
  },
  msgDateFormat: (date, timeZone) => {
    var convertedDate = new Date(date).toLocaleDateString(undefined, {
      timeZone: timeZone ? timeZone : "Asia/Kolkata",
    });

    return convertedDate.toString();
  },
  truncateDecimals :(num, digits)  =>{
      var numS = num.toString(),
          decPos = numS.indexOf('.'),
          substrLength = decPos == -1 ? numS.length : 1 + decPos + digits,
          trimmedResult = numS.substr(0, substrLength),
          finalResult = isNaN(trimmedResult) ? 0 : trimmedResult;

      return parseFloat(finalResult);
      
  },
  canEditUser: (currentUser, targetUser) => {
    // Check if the currentUser has a role exactly 1 higher than the targetUser
    if (currentUser === targetUser + 1) {
      return true;
    }
    return false;
  }
};

export default obj;
