import React from "react";
import { BsFillFileBarGraphFill } from "react-icons/bs";
import { FaArrowAltCircleRight } from "react-icons/fa";
const DropDown = ({ showMatch, layData, backData, data, title, res }) => {
  const renderBackOdds = (selection) => {
    const the_odds = backData;
    let filter_odds = [];
    if (title == "Match Odds") {
      filter_odds = the_odds?.filter(
        (todd) => todd.ri === selection?.SelectionId
      );
    } else {
      filter_odds = the_odds?.filter(
        (todd) => todd.runnerName === selection?.runnerName
      );
    }
    return (
      <>
        {title == "Match Odds" ? (
          <>
            <td className="back-3 p-0" style={{ cursor: "not-allowed" }}>
              <div className="light-blue rounded-0">
                <strong>{filter_odds[2]?.rt || "--"}</strong>
                <span className="d-block">{filter_odds[2]?.bv || "--"}</span>
              </div>
            </td>
            <td className="back-2 p-0" style={{ cursor: "not-allowed" }}>
              <div className="light-blue rounded-0">
                <strong>{filter_odds[1]?.rt || "--"}</strong>
                <span className="d-block">{filter_odds[1]?.bv || "--"}</span>
              </div>
            </td>
          </>
        ) : (
          <>
            {/* {filter_odds[0]?.ms != 1  ? (
                      <dd id="suspend" class="suspend-fancy">
                        <p id="info">
                          {filter_odds[0]?.ms == 9 
                            ? "Ball Running"
                            : filter_odds[0]?.ms == 2 
                              ? "In Active"
                              : filter_odds[0]?.ms == 3
                                ? "Suspended"
                                : filter_odds[0]?.ms == 4 
                                  ? "Closed"
                                  : "Suspended"}
                        </p>
                      </dd>
                    ) : ""} */}
            <td className="back-3 p-0" style={{ cursor: "not-allowed" }}>
              <div className="light-blue rounded-0">
                <strong>
                  {filter_odds[0]?.rt ? filter_odds[0]?.rt - 2 : "--"}
                </strong>
                <span className="d-block">{filter_odds[0]?.bv || "--"}</span>
              </div>
            </td>
            <td className="back-2 p-0" style={{ cursor: "not-allowed" }}>
              <div className="light-blue rounded-0">
                <strong>
                  {filter_odds[0]?.rt ? filter_odds[0]?.rt - 1 : "--"}
                </strong>
                <span className="d-block">{filter_odds[0]?.bv || "--"}</span>
              </div>
            </td>
          </>
        )}

        <td className="back-1s p-0" style={{ cursor: "not-allowed" }}>
          <div className="light-blue rounded-0">
            <strong>{filter_odds[0]?.rt || "--"}</strong>
            <span className="d-block">{filter_odds[0]?.bv || "--"}</span>
          </div>
        </td>
      </>
    );
  };
  const renderLayOdds = (selection) => {
    const the_odds = layData;
    let filter_odds = [];
    if (title == "Match Odds") {
      filter_odds = the_odds?.filter(
        (todd) => todd.ri === selection?.SelectionId
      );
    } else {
      filter_odds = the_odds?.filter(
        (todd) => todd.runnerName === selection?.runnerName
      );
    }

    return (
      <>
        <td className="lay-1 p-0" style={{ cursor: "not-allowed" }}>
          <div className="lay-all rounded-0">
            <strong>{filter_odds[0]?.rt || "--"}</strong>
            <span className="d-block">{filter_odds[0]?.bv || "--"}</span>
          </div>
        </td>
        <td className="lay-2 p-0" style={{ cursor: "not-allowed" }}>
          {title == "Match Odds" ? (
            <div className="dark-pink lay-all rounded-0">
              <strong>{filter_odds[1]?.rt || "--"}</strong>
              <span className="d-block">{filter_odds[1]?.bv || "--"}</span>
            </div>
          ) : (
            <div className="dark-pink lay-all rounded-0">
              <strong>
                {filter_odds[0]?.rt ? Math.abs(filter_odds[0]?.rt) - 1 : "--"}
              </strong>
              <span className="d-block">{filter_odds[0]?.bv || "--"}</span>
            </div>
          )}
        </td>

        <td className="lay-3 p-0" style={{ cursor: "not-allowed" }}>
          <div className="dark-pink lay-all rounded-0">
            <strong>
              {filter_odds[2]?.rt
                ? Math.abs(filter_odds[2]?.rt)?.toFixed(2)
                : "--"}
            </strong>
            <span className="d-block">{filter_odds[2]?.bv || "--"}</span>
          </div>
        </td>
      </>
    );
  };

  return (
    showMatch && (
      <tr colSpan={4}>
        {/* <td></td> */}
        <td></td>
        <td colSpan="5" className="px-0 gray-inner_table">
          <div className="d-flex flex-column justify-content-center align-items-center">
            <div
              style={{
                background: "#e2e8ed",
                borderBottom: "1px solid black",
                width: "900px",
              }}
              className="d-flex p-3"
            >
              <span style={{ marginRight: "15px" }}>
                Total Stakes <strong>PKU 0.0</strong>
              </span>
              <span style={{ marginRight: "15px" }}>
                Matched <strong>PKU {res?.totalMatched}</strong>
              </span>{" "}
              <FaArrowAltCircleRight
                style={{ marginRight: "10px" }}
                size={14}
                color="rgba(107, 114, 128, 1"
              />
              <span>In-Play</span>
            </div>
            <table className="selection-table" style={{ width: "900px" }}>
              <tbody className="">
                <tr className="">
                  <td width="40%" className="text-start border-0">
                    {data?.length > 0 && data?.length} Selections
                  </td>
                  <td className="refer-bet border-0" colSpan="2" width="20%">
                    100.8%
                  </td>
                  <td className="border-0 p-0" width="10%">
                    <div className="back-blue back-all-size">
                      <span>Back all</span>
                    </div>
                  </td>
                  <td className="border-0 p-0" width="10%">
                    <div className="lay-all back-all-size">
                      <span>Lay all</span>
                    </div>
                  </td>
                  <td className="refer-bet border-0" colSpan="2" width="20%">
                    99.5%
                  </td>
                </tr>
                {data?.length > 0 &&
                  data?.map((res, index) => {
                    return (
                      <tr key={index + 1}>
                        <td className="border-start-0">
                          <a>
                            <BsFillFileBarGraphFill
                              size={18}
                              color="rgba(59, 130, 246,1)"
                            />
                            <strong>
                              {" "}
                              {title == "Book Maker"
                                ? res?.runnerName
                                : res?.RunnerName}
                            </strong>
                          </a>
                        </td>
                        {renderBackOdds(res)}
                        {renderLayOdds(res)}
                      </tr>
                    );
                  })}
              </tbody>
            </table>{" "}
          </div>
        </td>
        {/* <td width="80" className="border-l"></td> */}
      </tr>
    )
  );
};

export default DropDown;
