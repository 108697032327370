import React, { useState, useContext } from "react";
import { Button, Table } from "react-bootstrap";
import { FilterBar } from "../../components/FilterBar";
import helpers from "../../utils/helpers";
import AuthContext from "../../context/AuthContext";
import ReactPaginate from "react-paginate";
import { AiFillMinusCircle, AiFillPlusCircle } from "react-icons/ai";
import { FaArrowAltCircleRight } from "react-icons/fa";
export const BettingHistoryTab = ({
  filter,
  setFilter,
  data,
  title,
  getData,
  betType,
  url,
  viewpage,
  handlePageClick,
  pageCount,
  redirectCasino,
}) => {
  const [id, setId] = useState("");
  let { user } = useContext(AuthContext);
  const [getProperty, setProperty] = useState("none");
  const showDetail = (event, id) => {
    const detailDiv = document.getElementById(id);

    if (detailDiv.style.display === "none") {
      detailDiv.style.setProperty("display", "contents");
      event.target.className = "fas fa-minus-square pe-2";
    } else {
      detailDiv.style.setProperty("display", "none");
      event.target.className = "fas fa-plus-square pe-2";
    }
  };

  return (
    <div className="common-container">
      <FilterBar filter={filter} setFilter={setFilter} getData={getData} betType={betType}/>
      {/* <div className="batting-content">
        <p>
          Betting History enables you to review the bets you have placed.
          Specify the time period during which your bets were placed, the type
          of markets on which the bets were placed, and the sport.
        </p>
        <p>Betting History is available online for the past 30 days.</p>
      </div> */}
      <div className="account-table batting-table profit_loss_table">
        <div className="responsive">
          {betType === "casino" ? (
            <Table>
              <thead>
                <tr>
                  <th scope="col">Bet ID</th>
                  <th scope="col"> PL ID</th>
                  <th scope="col">Market</th>
                  {/* <th scope="col">Selection</th> */}
                  {/* <th scope="col">Type</th> */}
                  <th scope="col">Bet Placed</th>
                  {/* <th scope="col">Odds Req.</th> */}
                  <th scope="col">Stake</th>
                  {/* <th scope="col">Avg. odds Matched</th> */}
                  <th scope="col">Profit / Loss</th>
                </tr>
              </thead>
              {data?.data && data?.data?.length > 0 ? (
                data?.data?.map((item, index) => {
                  // const resultTotalStake = item?.bets_list.reduce((a, v) => {
                  //   a = parseInt(a) + parseInt(v.amount);
                  //   return a;
                  // }, 0);

                  return (
                    <>
                      <tr
                        id="summary0"
                        style={{ display: "table-row" }}
                        key={index + 1}
                      >
                        <td>
                          {" "}
                          <i
                            id={"icon_" + item?.platformTxId}
                            className="fas fa-plus-square"
                            onClick={(e) => showDetail(e, item?.platformTxId)}
                          ></i>{" "}
                          {item?.platformTxId}
                        </td>
                        <td>{item?.clientName}</td>
                        <td id="title" className="align-L">
                          Casino
                          <span className="angle_unicode">▸</span>
                          <strong> {item?.casinoName}</strong>
                          <span className="angle_unicode">▸</span>
                          {item?.gameCode}
                        </td>
                        {/* <td>null</td> */}
                        {/* <td>back</td> */}
                        <td id="settledDate">
                          {" "}
                          {helpers.dateFormat(item.timeInserted, user.timeZone)}
                        </td>
                        {/* <td>0.00</td> */}
                        <td>{item?.betAmount}</td>
                        {/* <td>0.00</td> */}
                        <td>
                          {Math.sign(item?.playerPL) === -1 ? (
                            <span className="text-danger">
                              ({helpers.currencyFormat(item?.playerPL)})
                            </span>
                          ) : (
                            <span className="text-success">
                              (
                              {helpers.currencyFormat(Math.abs(item?.playerPL))}
                              )
                            </span>
                          )}
                        </td>
                      </tr>

                      <tr
                        className="expand"
                        id={item?.platformTxId}
                        style={{ display: getProperty }}
                      >
                        <td colSpan="7" className="expand_wrap">
                          <table className="table-commission">
                            <tbody>
                              <tr>
                                <th>Bet Taken</th>
                                <th>Odds Req.</th>
                                <th>Stake</th>
                                <th>Liability</th>
                                <th>Odds Matched</th>
                                <th>Action</th>
                              </tr>
                              <tr
                                id="txRow0"
                                style={{ display: "table-row" }}
                                className="even"
                              >
                                <td id="betID">
                                  {" "}
                                  {helpers.dateFormat(
                                    item.timeInserted,
                                    user.timeZone
                                  )}
                                </td>
                                <td>0.00</td>
                                <td>{item?.betAmount}</td>
                                <td>
                                  {item?.loseAmount ? item?.loseAmount : "-"}
                                </td>
                                <td>0.00</td>
                                <td>
                                  <Button
                                    className="theme_dark_btn"
                                    onClick={() =>
                                      redirectCasino(
                                        item?.clientName,
                                        item?.platform,
                                        item?.platformTxId,
                                        2
                                      )
                                    }
                                  >
                                    Get Result
                                  </Button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={10}>
                    <span>You have no bets in this time period.</span>
                  </td>
                </tr>
              )}
            </Table>
          ) : (
            <Table>
              <thead>
                <tr className="table-current-header">
                  {" "}
                  <th scope="col"> Bet ID</th>
                  <th scope="col">PL ID</th>
                  {betType != "parlay" && (
                    <>
                      <th scope="col">Market</th>
                      <th scope="col">Selection</th>
                    </>
                  )}
                  <th scope="col">Type</th>
                  <th scope="col">Bet placed</th>
                  <th scope="col">Odds req.</th> <th scope="col">Stake </th>{" "}
                  <th scope="col">Avg. Odds Matched </th>
                  <th scope="col">Profit/Loss </th>
                </tr>
              </thead>
              {data?.data && data?.data?.length > 0 ? (
                data?.data?.map((item) => {
                  return (
                    <>
                      <tr>
                        <td>
                          <div
                            style={{
                              textDecoration: "underline",
                              color: "#2779bf",
                            }}
                            className="d-flex"
                          >
                            {" "}
                            {betType == "fancy"
                              ? item?.sessionBetId
                              : item?.matchBetId || "N/A"}
                            <div style={{ marginLeft: "5px" }}>
                              {item?._id == id ? (
                                <AiFillMinusCircle
                                  onClick={() => {
                                    setId("");
                                  }}
                                  size={16}
                                  style={{ marginRight: "3px" }}
                                  color="rgba(59, 130, 246, 1)"
                                />
                              ) : (
                                <AiFillPlusCircle
                                  size={16}
                                  onClick={() => {
                                    setId(item?._id);
                                  }}
                                  style={{ marginRight: "3px" }}
                                  color="rgba(59, 130, 246, 1)"
                                />
                              )}
                            </div>
                          </div>
                        </td>
                        <td>{item?.clientName || "-"}</td>
                        {betType != "parlay" && (
                          <>
                            <td className="text-start">
                              {`${
                                item?.eventType === "4"
                                  ? "Cricket"
                                  : item?.eventType === "1"
                                  ? "Soccer"
                                  : item?.eventType === "2"
                                  ? "Tennis"
                                  : "Casino"
                              }`}
                              <FaArrowAltCircleRight
                                style={{ margin: "0 3px" }}
                                size={22}
                                color="rgba(107, 114, 128, 1)"
                              />
                              <strong>
                                {betType === "casino"
                                  ? item?.casinoName
                                  : item?.matchName}
                              </strong>
                              <FaArrowAltCircleRight
                                style={{ margin: "0 3px" }}
                                size={22}
                                color="rgba(107, 114, 128, 1)"
                              />
                              {betType === "sportBook" && item?.fancyName}
                              {betType === "fancy" && "Fancy Bet"}{" "}
                              {betType === "casino" && item?.casinoType}{" "}
                              {betType === "betfair" && "BetFair"}{" "}
                              {betType === "bookmaker" && "Bookmaker"}{" "}
                            </td>{" "}
                            <td>
                              {betType === "fancy"
                                ? item?.fancyName
                                : betType === "sportBook"
                                ? item?.runnerName
                                : item?.teamName}
                            </td>
                          </>
                        )}
                        <td>
                          {" "}
                          {betType == "fancy"
                            ? item?.type
                            : item?.betType || "N/A"}{" "}
                        </td>
                        <td>
                          {" "}
                          {helpers.dateFormat(
                            item?.timeInserted,
                            user.timeZone
                          )}
                        </td>
                        <td>
                          {" "}
                          {betType == "fancy"
                            ? item?.betRun + "/" + item?.bhav
                            : item?.bhav || "N/A"}{" "}
                        </td>
                        <td> {item?.amount || "N/A"} </td>{" "}
                        <td>
                          {" "}
                          {betType == "fancy"
                            ? item?.betRun + "/" + item?.bhav
                            : item?.bhav || "N/A"}{" "}
                        </td>
                        {item?.isDeclared ? (
                          item?.status !== "voided" ? (
                            betType == "betfair" ||
                            betType == "Bookmaker" ||
                            betType === "toss" ||
                            betType === "tie" ? (
                              <td>
                                {(item?.teamSelectionWin == item?.selectionId &&
                                  item?.betType == "back") ||
                                (item?.teamSelectionWin != item?.selectionId &&
                                  item?.betType == "lay") ? (
                                  <span className="text-success">
                                    {item?.profitAmount}
                                  </span>
                                ) : (
                                  <span className="text-danger">
                                    -({item?.loseAmount})
                                  </span>
                                )}
                              </td>
                            ) : betType === "sportBook" ? (
                              <td>
                                {item?.teamSelectionWin &&
                                item?.teamSelectionWin
                                  .split(",")
                                  .includes(item?.fancySelectionId) ? (
                                  <span className="text-success">
                                    {item?.profitAmount}
                                  </span>
                                ) : (
                                  <span className="text-danger">
                                    -({item?.loseAmount})
                                  </span>
                                )}
                              </td>
                            ) : betType == "parlay" ? (
                              <td>
                                {item?.result == "win" ? (
                                  <span className="text-success">
                                    {item?.profitAmount}
                                  </span>
                                ) : (
                                  <span className="text-danger">
                                    {item?.loseAmount}
                                  </span>
                                )}
                              </td>
                            ) : (
                              <td>
                                {item?.type == "No" ? (
                                  item?.decisionRun < item?.betRun ? (
                                    <span className="text-success">
                                      {item?.profitAmount}
                                    </span>
                                  ) : (
                                    <span className="text-danger">
                                      -({item?.loseAmount})
                                    </span>
                                  )
                                ) : item?.decisionRun >= item?.betRun ? (
                                  <span className="text-success">
                                    {item?.profitAmount}
                                  </span>
                                ) : (
                                  <span className="text-danger">
                                    -({item?.loseAmount})
                                  </span>
                                )}
                              </td>
                            )
                          ) : (
                            <td>0.00</td>
                          )
                        ) : (
                          <td>Not Settled</td>
                        )}
                      </tr>
                      {item?._id == id && betType != "parlay" && (
                        <tr
                          style={{ background: "#F3F2F1" }}
                          className="expand"
                        >
                          <td
                            colSpan="11"
                            className="expand_wrap p-0"
                            // style={{ background: "rgb(243, 242, 241)" }}
                          >
                            <div
                              style={{ background: "#F3F2F1" }}
                              className="d-flex justify-content-center align-items-center w-100"
                            >
                              <table
                                style={{ width: "90%" }}
                                className="table-commission m-0 p-0"
                              >
                                <tbody>
                                  <tr>
                                    <th className="tabel-profit-loss-header">
                                      Bet Taken
                                    </th>
                                    <th className="tabel-profit-loss-header">
                                      Odds Req.
                                    </th>
                                    <th className="tabel-profit-loss-header">
                                      Stake
                                    </th>{" "}
                                    <th className="tabel-profit-loss-header">
                                      Liability
                                    </th>
                                    <th className="tabel-profit-loss-header">
                                      Odds Matched
                                    </th>
                                  </tr>

                                  <tr
                                    id="txRow0"
                                    style={{
                                      display: "table-row",
                                      background: "white",
                                    }}
                                    className="even"
                                  >
                                    <td id="betID">
                                      {" "}
                                      {helpers.dateFormat(
                                        item?.timeInserted,
                                        user.timeZone
                                      )}
                                    </td>
                                    <td>
                                      {" "}
                                      {betType == "fancy"
                                        ? item?.betRun + "/" + item?.bhav
                                        : item?.bhav || "N/A"}{" "}
                                    </td>
                                    <td> {item?.amount || "N/A"} </td>{" "}
                                    <td>
                                      {" "}
                                      <span className="text-danger">
                                        ({item?.loseAmount || "-"})
                                      </span>{" "}
                                    </td>
                                    <td>
                                      {" "}
                                      {betType == "fancy"
                                        ? item?.betRun + "/" + item?.bhav
                                        : item?.bhav || "N/A"}{" "}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>{" "}
                            </div>
                          </td>
                        </tr>
                      )}

                      {item?._id == id &&
                        item?.bets?.length > 0 &&
                        betType == "parlay" && (
                          <tr
                            style={{ background: "#F3F2F1" }}
                            className="expand"
                          >
                            <td
                              colSpan="11"
                              className="expand_wrap p-0"
                              style={{ background: "rgb(243, 242, 241)" }}
                            >
                              <div
                                style={{ background: "#F3F2F1" }}
                                className="d-flex justify-content-center align-items-center w-100"
                              >
                                <table
                                  style={{ width: "90%" }}
                                  className="table-commission p-0 m-0"
                                >
                                  <tbody>
                                    <tr>
                                      <th className="tabel-profit-loss-header">
                                        BetFair Event Id
                                      </th>
                                      <th className="tabel-profit-loss-header">
                                        Market
                                      </th>
                                      <th className="tabel-profit-loss-header">
                                        Selection
                                      </th>{" "}
                                      <th className="tabel-profit-loss-header">
                                        Odds Req.
                                      </th>
                                      <th className="tabel-profit-loss-header">
                                        Odds Matched
                                      </th>{" "}
                                      <th className="tabel-profit-loss-header">
                                        Win/Loss
                                      </th>
                                    </tr>

                                    {item?.bets?.map((res) => {
                                      return (
                                        <tr
                                          id="txRow0"
                                          style={{
                                            display: "table-row",
                                            background: "white",
                                          }}
                                          className="even"
                                        >
                                          <td id="betID">{res?.eventId}</td>
                                          <td className="text-start">
                                            {`${
                                              res?.eventType === "4"
                                                ? "Cricket"
                                                : res?.eventType === "1"
                                                ? "Soccer"
                                                : res?.eventType === "2"
                                                ? "Tennis"
                                                : "Casino"
                                            }`}
                                            <FaArrowAltCircleRight
                                              style={{ margin: "0 3px" }}
                                              size={14}
                                              color="rgba(107, 114, 128, 1)"
                                            />
                                            <strong>{res?.matchName}</strong>
                                            <FaArrowAltCircleRight
                                              style={{ margin: "0 3px" }}
                                              size={14}
                                              color="rgba(107, 114, 128, 1)"
                                            />
                                            {res?.fancyName}
                                          </td>{" "}
                                          <td> {res?.runnerName || "N/A"} </td>{" "}
                                          <td>{res?.bhav || "-"}</td>
                                          <td>{res?.bhav || "-"}</td>
                                          <td>
                                            {res?.isDeclared ? (
                                              res?.result == "win" ? (
                                                <span className="text-success">
                                                  {res?.profitAmount}
                                                </span>
                                              ) : (
                                                <span className="text-danger">
                                                  {res?.loseAmount}
                                                </span>
                                              )
                                            ) : (
                                              "Not Settled"
                                            )}
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </table>{" "}
                              </div>
                            </td>
                          </tr>
                        )}
                    </>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={10}>
                    <span>You have no bets in this time period.</span>
                  </td>
                </tr>
              )}
            </Table>
          )}

          <div className="bottom-pagination">
            <ReactPaginate
              breakLabel="..."
              nextLabel=" >"
              forcePage={viewpage}
              onPageChange={handlePageClick}
              pageRangeDisplayed={10}
              pageCount={pageCount}
              previousLabel="< "
              renderOnZeroPageCount={null}
              activeClassName="p-1"
              activeLinkClassName="pagintion-li"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
